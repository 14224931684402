$size: 86px;

.lds-heart {
    display: inline-block;
    position: relative;
    width: $size * 2.5;
    height: $size * 2.5;
    transform: rotate(45deg);
    transform-origin: $size * 1.05 $size * 1.05;
    position: fixed;
    z-index: 1000;
    left: 50%;
    top: 50%;
    margin-left: -$size * 1.05;
    margin-top: -$size * 1.05;
    opacity: .75;
    
    div {
        top: $size;
        left: $size;
        position: absolute;
        width: $size;
        height: $size;
        background: #472550;
        animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

        box-shadow: 10px -2px 5px -5px #fff;

        &:after,
        &:before {
            content: " ";
            position: absolute;
            display: block;
            width: $size;
            height: $size;
            
            background: #472550;
        }

        &:before {
            left: -$size / 4 * 3;
            border-radius: 50% 0 0 50%;
        }

        &:after {
            top: -$size / 4 * 3;
            border-radius: 50% 50% 0 0;
            box-shadow: 10px -7px 5px -5px #fff;
        }
    }
}

@keyframes lds-heart {
    0% {
      transform: scale(0.95);
    }
    5% {
      transform: scale(1.1);
    }
    39% {
      transform: scale(0.85);
    }
    45% {
      transform: scale(1);
    }
    60% {
      transform: scale(0.95);
    }
    100% {
      transform: scale(0.9);
    }
}