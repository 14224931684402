.foto__navigation {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    height: 45px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background:linear-gradient(180deg, #472550 80%, transparent 90%);
    color: #fff;
    font-size: 16px;

    > * {
        padding: 10px;
    }
}

.guest {
    &__list {
        width: 100%;
        padding: 0;

        li {
            list-style: none;
            padding: 10px 10px 10px 20px;
            margin: 0 0 25px 0;
            height: 50px;
            vertical-align: middle;
            text-align: left;
            position: relative;
            cursor: pointer;
            background-color:rgba(71, 37, 80, 1);
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 15px 5px 15px 0;
            transition: all .3s ease-in-out;

            &:hover,
            &:active {
                background-color:rgba(71, 37, 80, 1);
                box-shadow: 0 0 5px 0 rgba(71, 37, 80, 1);
                font-size: 30px;
            }
        }
    }

    &__prompt {
        background-color: rgba(71, 37, 80, .75);
        color: #fff;
        border-radius: 5px;
        padding: 10px 15px;

        &__link {
            font-size: 20px;
            text-decoration: underline;;
        }
    }
}

.challenge {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 -8px;
    }

    &__box {
        padding: 5px;
        position: relative;
        cursor: pointer;
        width: 100%;
        min-height: 150px;
        min-width: 0;
        flex: calc(50% - 16px);
        box-shadow: 0 0 3px 0 #472550;
        border-radius: 15px 0 15px 0;
        margin: 8px;
        background-color: rgba(255,255,255,.5);
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--done {
            background-color: rgba(200,255,200,.5);
        }
        
        &--add {
            background-color: rgba(71, 37, 80, 1);
            color: #fff;
            min-height: 100px;
        }

        &--spontan {
            background-color: #e7e4eb;
            color: #472550;
            min-height: 100px;
        }

        @media (min-width: 800px) {
            flex: calc(33% - 16px);
        }
    
        @media (min-width: 1200px) {
            flex: calc(25% - 16px);
        }
    }

    &__title {
        display: block;
        margin-bottom: 5px;;
        font-size: 18px;
        font-family: Montserrat;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__description {
        display: block;
        font-size: 16px;
    }

    &__dropzone {
        position: relative;
        display: flex;
        width: 100%;
        height: 100px;
        padding: 10px;
        box-shadow: 0 0 3px 0 #472550;
        border-radius: 5px;
        justify-content: center;
        align-items: center;

        background-color: rgba(71, 37, 80, 1);
        color: #fff;

        &--small {
            height: auto;
            font-size: 16px;
        }

        input[type='file'] {
            font-size: 18px;
            display: block;
            width: 100%;
            border: none;
            text-transform: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            &:focus {
                outline: none;
            }
        }
    }

    &__more {
        margin-top: 30px;
        padding-top: 30px;
        border-top: solid 1px #472550;
        font-size: 18px;

        > b {
            font-family: Montserrat;
            margin-bottom: 15px;
            display: block;
        }
    }

    &__picture {
        box-shadow: 0 0 3px 0 #472550;
        background-color: rgba(255,255,255,.75);
        border-radius: 5px 5px 5px 0;
        overflow: hidden;
        margin-bottom: 30px;

        > i {
            display: block;
            text-align: right;
            padding: 2px 5px
        }
    }
    
    &__picture {
        img {
            max-width: 100%;
            display: block;
            margin: auto;
        }
    }

    &__view-all {
        margin: 100px 0;
        background-color: #e7e4eb;
        padding: 25px 10px;
        border: solid 1px #472550;
        border-radius: 15px;
        font-size: 20px;
        box-shadow: 0 0 5px 0 rgba(71, 37, 80, .5);
    }

    &__change-user {
        font-size: 20px;
        text-decoration: underline;
        margin: 50px 0;
        display: block;
    }

    &__full__list {

        &__item {
            margin: 0;
            padding: 25px 0;
            border-top: solid 1px #472550;

            .challenge__picture {
                margin: 20px 0 0 0;
                
                padding: 0;
                position: relative;
                cursor: pointer;
                min-height: 150px;
                min-width: 0;
                max-width: 45%;
                flex: 45%;
                box-shadow: 0 0 3px 0 #472550;
                border-radius: 15px 0 15px 0;
                overflow: hidden;
                background-color: rgba(255,255,255,.5);
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:first-child {
                    flex: 100%;
                    max-width: 100%;
                }

                &__wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }

            b,
            i {
                display: block;
            }

            i {
                font-size: 18px;
            }

            .challenge__accept {
                display: block;
                font-size: 20px;
                text-decoration: underline;
                text-align: right;
                margin-top: 25px;
            }
        }
    }
}

.leader__list {
    text-align: left;
    padding-left: 0;
    list-style: none;
    counter-reset: listLeader;

    li {
        counter-increment: listLeader;
        position: relative;
        padding-left: 25px;
        font-size: 18px;
        padding-top: 4px;

        &:before {
            content: counter(listLeader) ".";
            display: block;
            font-size: 18px;
            text-align: center;
            position: absolute;
            left: 0;
            top: 3px;
            background-color: #e7e4eb;
            width: 20px;
            height: 20px;
            border-radius: 100%;
        }

        &.empty:before {
            content: "-";
        }
    }
}

.error {
    &__wrapper {
        position: fixed;
        z-index: 10000;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        border-top: solid 24px #dd0000;
        border-radius: 5px 5px 0 0;
        opacity: .9;

        &:before {
            content: "Fehler:";
            position: absolute;
            top: -19px;
            left: 5px;
            font-size: 12px;
            color: #fff;
        }
    }

    &__item {
        display: block;
        background-color: #fff;
        border-top: solid 1px #333;
        color: #333;
        font-size: 16px;
        text-align: left;
        padding: 5px 20px 5px 5px;

        &:first-child {
            border-top: none;
        }
    }

    &__close {
        position: absolute;
        top: -21px;
        right: 15px;
        color: #fff;
        background-color: #ff0000;
        border: solid 1px #fff;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        font-size: 12px;
        padding-top: 1px;
    }
}

.stage__loading {
    pointer-events: none;
}