.stage {
  width: 100vw;
  height: 100vh;
  margin: auto;
  position: fixed;
  left: 0;
  top: 45px;
  overflow: auto;

  @media (min-width: 992px) {
    top: 0;
  }
}

.stage:before,
.stage:after {
  width: 100vw;
  height: 100vh;
  margin: auto;
  position: fixed;
  left: 0;
  top: 0;
  content: "";
  display: block;
  z-index: -1;
  opacity: 0;
  transition: opacity .5s ease-in-out;
}

.stage:focus {
  outline: none;
}
.stage.dark {
  color: #fff;
}
.stage.dark:before {
  background: linear-gradient(
      155deg,
      #000 0%,
      #210821 25%,
      #472550 75%,
      #000 100%
    )
    fixed no-repeat;
    opacity: 1;
}
.stage.dark:after {
  opacity: 0;
  background: linear-gradient(
      155deg,
      #b6acc0 0%,
      #fff 25%,
      #e7e4eb 75%,
      #b6acc0 100%
    )
    fixed no-repeat;
}

.stage.light {
  color: #472550;
}  

.stage.light:after {
  background: linear-gradient(
      155deg,
      #b6acc0 0%,
      #fff 25%,
      #e7e4eb 75%,
      #b6acc0 100%
    )
    fixed no-repeat;
  opacity: 1;
}
.stage.light:before {
  opacity: 0;
  background: linear-gradient(
      155deg,
      #000 0%,
      #210821 25%,
      #472550 75%,
      #000 100%
    )
}

.stage__content {
  width: 100%;
  min-height: 200%;
  max-width: 90%;
  padding: 500px 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  z-index: 100;

  &--foto {
    padding: 100px 0;
    min-height: 100%;
    opacity: 1;

    
    @media (min-width: 800px) {
      padding: 75px 0 50px 0;
    }
  }

  opacity: 0;
  transition: opacity 1.5s ease-out;

  @media (min-width: 800px) {
    max-width: 600px;
  }

  @media (min-width: 1024px) {
    max-width: 700px;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }

  &--wide {
    @media (min-width: 800px) {
      max-width: 90%;
    }
  }
}

.app--initialized .stage__content {
  opacity: 1;
}

.scroll-hint {
  position: fixed;
  bottom: 50px;
  right: 25px;
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity ease-in-out .5s, width ease-in-out .5s, height ease-in-out .5s;
}

.scroll-hint.visible {
  opacity: .75;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.scroll-hint__chevron {
  position: absolute;
  width: 28px;
  height: 8px;
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  animation: move 3s ease-out infinite;
}

.scroll-hint__chevron:first-child {
  animation: move 3s ease-out 1s infinite;
}

.scroll-hint__chevron:nth-child(2) {
  animation: move 3s ease-out 2s infinite;
}

.scroll-hint__chevron:before,
.scroll-hint__chevron:after {
  content: ' ';
  position: absolute;
  top: 0;
  height: 100%;
  width: 51%;
  background: #fff;
}

.scroll-hint__chevron:before {
  left: 0;
  transform: skew(0deg, 30deg);
}

.scroll-hint__chevron:after {
  right: 0;
  width: 50%;
  transform: skew(0deg, -30deg);
}

@keyframes move {
  25% {
    opacity: 1;

  }
  33% {
    opacity: 1;
    transform: translateY(30px);
  }
  67% {
    opacity: 1;
    transform: translateY(40px);
  }
  100% {
    opacity: 0;
    transform: translateY(55px) scale3d(0.5, 0.5, 0.5);
  }
}

.scroll-hint__text {
  display: block;
  margin-top: 75px;
  margin-left: -30px;
  font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: .25;
  animation: pulse 2s linear alternate infinite;
}

@keyframes pulse {
  to {
    opacity: 1;
  }
}