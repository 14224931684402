.backdrop {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(71, 37, 80, .75);

    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        content: "Schliessen";
        position: absolute;
        top: 5px;
        right: 5px;
        color: #fff;
        display: block;
        text-align: center;
        padding: 15px;
        font-size: 16px;
        text-decoration: underline;
    }

    &:after {
        content: attr(caption);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        color: #fff;
        display: block;
        text-align: center;
        padding: 15px;
        font-size: 18px;
        background-color: rgba(71, 37, 80, .5);
    }

    img {
        max-width: 95%;
        width: auto;
        max-height: 95%;
        height: auto;
    }
}