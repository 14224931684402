@import-normalize;

body {
  font-family: 'Catamaran', sans-serif;
  font-weight: 300;
  font-size: 24px;
  padding: 0;
  margin: 0;
  height: 100vh;

  * {
    
  box-sizing: border-box;
  }
}

#root {
  width: 100%;
  height: 100%;
}

h1, h2 {
  font-weight: 100;
  font-size: 50px;
  line-height: 100%;
  text-transform: uppercase;
  margin: 0;
}

h4 + p {
  margin-top: -15px;
}

a {
  color: inherit;
  position: relative;
  display: inline-flex;
  text-decoration: none;

  &:after {
    content: attr(link-title);
    position: absolute;
    top: 0;
    line-height: .9;
    color: transparent;
    border-bottom: solid 6px rgba(255,255,255,.25);
    transition: all .2s ease-out;

    @media (min-width: 992px) {
      line-height: 17px;
    }
  }

  &:hover:after {
    border-bottom: solid 2px rgba(255,255,255,1);
    top: 6px;
  }
}

.flex-end {
  justify-self: flex-end;
  margin-left: auto;
}

.light a {
  &:after {
    border-bottom: solid 6px rgba(71,37,80,.25);
  }

  &:hover:after {
    border-bottom: solid 2px rgba(71,37,80,1);
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 100px;
  }

  h2 {
    font-size: 240px;
  }
}

.grid {
  display: grid;
  grid-template-rows: auto;
  column-gap: 0;
  row-gap: 0;

  &--8 {
    grid-template-columns: auto auto 1fr;
  }

  text-align: left;
  font-size: 18px;

  > div {
    border-bottom: solid 1px #333;
    padding: 15px;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;

  transition: opacity 1s ease-out;

  @media (min-width: 992px) {
    top: 20px;
    left: 20px;
  }
}

.navigation {
  display: none;
  flex-direction: column;

  &--open {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    width: 100vw;
    height: 100vh;
    padding: 50px;
    background-color: rgba(71,37,80,.95);
    color: #fff;
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: normal;
    justify-content: normal;
    width: auto;
    height: auto;
    padding: 0;
    background-color: transparent;
    color: inherit;
  }

  > a {
    display: block;
    position: relative;

    &.active {
      font-weight: 600;
    }

    &:before {
      content: "> ";
      position: absolute;
      left: -10px;
    }
  }

  &__icon {
    display: inline-block;
    cursor: pointer;
    position: fixed;
    z-index: 1000;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;

    @media (min-width: 992px) {
      display: none;
    }
    
    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 4px;
      border-radius: 5px;
      background-color: currentColor;
      margin: 6px 0;
      transition: 0.4s;
      transform-origin: center left;
    }

    &--open {
      .bar1, .bar2, .bar3 {
        color: #fff;
      }
    }
    
    &--open .bar1 {
      transform: rotate(45deg);
    }
    
    &--open .bar2 {opacity: 0;}
    
    &--open .bar3 {
      transform: rotate(-45deg);
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: height 1s ease-out;

  &:before,
  &:after {
    content: "";
    opacity: 0;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    transition: opacity .5s ease-out;
  }

  &:before {
    //background: linear-gradient(to bottom, #472550, #000);
  }

  &:after {
    //background: linear-gradient(to bottom, #fff, #e7e4eb);
  }
}

.countdown {
  text-align: center;

  h2 {
    font-weight: 100;
    font-size: 120px;
    line-height: 100%;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 0;
  
    opacity: 1;
    transition: all 1s ease-out;
  }
}

.app--initialized  {
  .countdown h2 {
    opacity: 0;
    font-size: 1px;
  }

  header {
    opacity: 1;
    z-index: 102;
  }

  footer {
    bottom: -60px;
    left: 0;
    height: 60px;
    z-index: 101;

    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
    }
  }

  &.light footer {
    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
}

@media (min-width: 992px) {
  .countdown h2 {
    font-size: 160px;
  }
}

.form {
  display: block;
  width: 100%;
  max-width: 500px;
  text-align: left;

  label {
    position: relative;
    display: block;
    margin-bottom: 12px;

    input,
    textarea {

      &[type='text'],
      &[type='password'],
      &[rows] {
        display: block;
        width: 100%;
        border: solid 1px #472550;
        color: #472550;
        border-radius: 5px;
        font-size: inherit;
        font-family: inherit;
        padding: 2px 5px;
        background-color: rgba(255,255,255,.5);

        &:focus,
        &.valid {
          background-color: rgba(255,255,255,1);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 5px 0px #472550;
        }
      }

      &[type='checkbox'] {
        margin-left: 38px;
        border: none;
        color: transparent;
        appearance: none;
        position: relative;

        &:before {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          border: 2px solid #472550;
          border-radius: 5px;
          position: absolute;
          left: -38px;
          top: -20px;
        }

        &:after {
          content: "X";
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          color: #472550;
          display: block;
          width: 28px;
          height: 28px;
          position: absolute;
          left: -38px;
          top: -20px;
          transition: transform .2s ease-out;

          transform: scale(0);
        }

        &:checked:after {
          content: "X";
          transform: scale(1);
        }

        &:hover:after {
          opacity: .2;
          transform: scale(.5);
        }
        
        &:checked:hover:after {
          opacity: .8;
          transform: scale(.75);
        }

        &:focus:before {
          outline: none;
          box-shadow: 0 0 5px 0px #472550;
        }
      }
    }

    input,
    textarea,
    select {
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        border: 1px solid #472550;
        -webkit-text-fill-color: #472550;
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 5px 0px #472550;
      }
    }

    &.zip-code {
      display: inline-block;
      width: 25%;
    }

    &.city {
      display: inline-block;
      width: 70%;
      margin-left: 5%;
    }
  }

  
}

input[type='submit'], button, .button {
  display: block;
  margin: 12px auto;
  padding: 2px 48px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  background-color: #472550;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  opacity: 1;

  transition: opacity .2s ease-out;

  &:hover {
    background-color: #e7e4eb;
    color: #472550;
    border-color: #472550
  }

  &.button--small {
    font-size: 18px;
  }

  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px 0px #472550;
  }
}